var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('filter-bar', {
    attrs: {
      "name": "categories",
      "pSearch": _vm.search
    }
  }), _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 overflow-hidden"
  }, [_vm._m(0), _c('div', {
    staticClass: "w-full flex justify-end"
  }, [_c('router-link', {
    staticClass: "flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    attrs: {
      "to": "/categories/new"
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("add")]), _vm._v(" add category ")])], 1)]), _c('div', {
    staticClass: "overflow-x-auto"
  }, [_c('base-table', {
    attrs: {
      "refresh": _vm.refresh,
      "content": _vm.content,
      "headers": _vm.headers,
      "name": "categories",
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser
    }
  })], 1), _c('div', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "flex flex-col items-center my-12"
  }, [_vm.paginate.lastpage > 1 ? _c('div', {
    staticClass: "flex text-gray-700"
  }, [_vm.paginate.currentpage > 1 ? _c('button', {
    staticClass: "h-8 w-8 focus:outline-none mr-6 flex justify-center text-green-700 items-center rounded-full bg-gray-200 hover:bg-green-600 hover:text-white cursor-pointer",
    attrs: {
      "disabled": _vm.paginate.currentpage == 1
    },
    on: {
      "click": _vm.prev
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v(" keyboard_arrow_left ")])]) : _vm._e(), _c('div', {
    staticClass: "flex h-8 font-medium rounded-full bg-gray-200"
  }, [_vm.paginate.currentpage != _vm.paginate.lastpage ? _c('div', {
    staticClass: "w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-green-600 text-white"
  }, [_vm._v(" " + _vm._s(_vm.paginate.currentpage) + " ")]) : _c('div', {
    staticClass: "w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
  }, [_vm.paginate.currentpage != 1 ? _c('span', [_vm._v(" 1 ")]) : _vm._e()]), _vm.paginate.currentpage >= 1 && _vm.paginate.lastpage != 1 ? _c('div', {
    staticClass: "w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
  }, [_vm._v(" ... ")]) : _vm._e(), _c('button', {
    staticClass: "w-8 md:flex justify-center items-center hover:bg-green-600 hover:text-white focus:outline-none hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full",
    class: _vm.paginate.currentpage == _vm.paginate.lastpage ? 'bg-green-600  text-white' : ' '
  }, [_vm._v(" " + _vm._s(_vm.paginate.lastpage) + " ")])]), _vm.paginate.currentpage < _vm.paginate.lastpage ? _c('button', {
    staticClass: "h-8 w-8 ml-6 focus:outline-none flex justify-center text-green-700 items-center rounded-full bg-gray-200 hover:bg-green-600 hover:text-white cursor-pointer",
    attrs: {
      "disabled": _vm.paginate.currentpage == _vm.paginate.lastpage
    },
    on: {
      "click": _vm.next
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v(" keyboard_arrow_right ")])]) : _vm._e()]) : _vm._e()])])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "text-2xl font-bold text-green-700"
  }, [_vm._v("Categories")])]);

}]

export { render, staticRenderFns }